import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Alert, Box, Container, FormHelperText, Paper, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Helmet} from "react-helmet-async";
import Utils from "./component/Utils";

const Reset = (props) => {

    const {key} = useParams();
    const {t} = useTranslation('reset');
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: false,
        message: '',
        email: '',
        password: '',
        passwordError: false,
        passwordValidate: '',
        passwordConfirm: '',
        passwordConfirmError: false,
        passwordConfirmValidate: ''
    });

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setValues((values) => ({
            ...values, [name]: value,
        }));
        if (name === "password") {
            if (value.trim().length > 7) {
                setValues((values) => ({
                    ...values, passwordError: false, passwordValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
                }));
            }
        } else if (name === "passwordConfirm") {
            if (value.trim().length > 7 && value.trim() === values.password) {
                setValues((values) => ({
                    ...values, passwordConfirmError: false, passwordConfirmValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, passwordConfirmError: true, passwordConfirmValidate: t("form.passwordConfirmValidate")
                }));
            }
        }
    };

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/reset/" + key).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, email: response.data.email
                }));
            }, error => {
                console.log(error);
                navigate('/info', {
                    state: {
                        alert: "error",
                        summary: t("translation:error.title"),
                        details: error.response.data.message
                    }
                });
            }
        );
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!(event.target.elements["password"].value.trim().length > 7)) {
            setValues((values) => ({
                ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
            }));
            return false;
        }
        if (!(event.target.elements["passwordConfirm"].value.trim().length > 7 && event.target.elements["passwordConfirm"].value.trim() === values.password)) {
            setValues((values) => ({
                ...values, passwordConfirmError: true, passwordConfirmValidate: t("form.passwordConfirmValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));

        axios.post(Utils.getApiUrl() + "/reset", {"key": key, "password": values.password}).then((response) => {
                console.log(response);
                navigate('/info', {
                    state: {
                        alert: "success",
                        summary: t("messages.summary"),
                        details: t("messages.details")
                    }
                });
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };


    return (
        <>
            <Container component="main">
                <Helmet>
                    <title>{t("title")} | {t("translation:nav.brand")}</title>
                </Helmet>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{t("title")}</Typography>
                </Paper>

                <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                    {values.message && (
                        <Alert severity="error">{values.message}</Alert>
                    )}

                    <TextField name="email" label={t("form.email")} value={values.email} margin="normal" fullWidth disabled/>

                    <TextField type="password" name="password" label={t("form.password")} margin="normal" fullWidth required
                               value={values.password} onChange={handleChange} error={values.passwordError} helperText={values.passwordValidate}/>
                    <FormHelperText>{t("form.passwordHelp")}</FormHelperText>

                    <TextField type="password" name="passwordConfirm" label={t("form.passwordConfirm")} margin="normal" fullWidth required
                               value={values.passwordConfirm} onChange={handleChange} error={values.passwordConfirmError} helperText={values.passwordConfirmValidate}/>
                    <FormHelperText>{t("form.passwordConfirmHelp")}</FormHelperText>

                    <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                   sx={{mt: 3, mb: 2}}>{t("form.button")}</LoadingButton>
                </Box>
            </Container>
        </>
    );
};

export default Reset;
