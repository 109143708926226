import React, {useEffect, useState} from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import {Autocomplete, Box, ClickAwayListener, IconButton, Slide, TextField} from '@mui/material';
import {Search} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import SecurityUtils from "../component/SecurityUtils";
import Utils from "../component/Utils";

const Searchbar = (props) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        slideOpened: false,
        query: "",
        inputQuery: "",
        options: [],
        products: [],
        users: [],
        stories: []
    });

    useEffect(() => {
        loadOptions();
    }, []);

    const loadOptions = async () => {
        await axios.get(Utils.getApiUrl() + "/products/size/5/sort/views,desc" + (values.inputQuery ? "/name/" + values.inputQuery : ""),
            {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                if (response.data && response.data.content) {
                    setValues((values) => ({
                        ...values, products: response.data.content.map(product => ({"label": product.name, "group": t("nav.product")}))
                    }))
                } else {
                    setValues((values) => ({
                        ...values, products: []
                    }))
                }
            }, error => {
                console.log(error);
            }
        );
        await axios.get(Utils.getApiUrl() + "/users/size/5" + (values.inputQuery ? "/name/" + values.inputQuery : ""),
            {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                if (response.data && response.data.content) {
                    setValues((values) => ({
                        ...values, users: response.data.content.map(user => ({"label": user.name, "group": t("nav.users")}))
                    }))
                } else {
                    setValues((values) => ({
                        ...values, users: []
                    }))
                }
            }, error => {
                console.log(error);
            }
        );
        await axios.get(Utils.getApiUrl() + "/stories/size/5/sort/views,desc" + (values.inputQuery ? "/title/" + values.inputQuery : ""),
            {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                if (response.data && response.data.content) {
                    setValues((values) => ({
                        ...values, stories: response.data.content.map(story => ({"label": story.title, "group": t("nav.story")}))
                    }))
                } else {
                    setValues((values) => ({
                        ...values, stories: []
                    }))
                }
            }, error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        setValues((values) => ({
            ...values, options: [...values.products, ...values.users, ...values.stories]
        }))
    }, [values.products, values.users, values.stories]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            loadOptions();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [values.inputQuery]);

    useEffect(() => {
        if (values.inputQuery.trim().length > 0) {
            setValues((values) => ({
                ...values, slideOpened: false
            }));
            navigate('/search/' + values.inputQuery);
        }
    }, [values.query]);

    const handleKeydown = (event) => {
        var code = (event.keyCode ? event.keyCode : event.which);
        if (code === 13 && values.inputQuery.trim().length > 0) {
            setValues((values) => ({
                ...values, slideOpened: false
            }));
            navigate('/search/' + values.inputQuery);
        }
    };

    const handleOpen = () => {
        setValues((values) => ({
            ...values, slideOpened: true
        }));
    };
    const handleClose = () => {
        setValues((values) => ({
            ...values, slideOpened: false
        }));
    };

    return (
        <>
            <Autocomplete freeSolo options={values.options} groupBy={(option) => option.group}
                          sx={{width: '50%', display: {xs: 'none', sm: 'inline-flex'}}} value={values.query} inputValue={values.inputQuery}
                          onChange={(event, value) => {
                              setValues((values) => ({...values, query: value}));
                          }} onKeyDown={handleKeydown}
                          onInputChange={(event, value) => {
                              setValues((values) => ({...values, inputQuery: value}));
                          }}
                          renderInput={(params) =>
                              <TextField {...params} variant='filled' placeholder={t("nav.search")} size='small'
                                         InputProps={{
                                             ...params.InputProps, disableUnderline: true,
                                             startAdornment: <React.Fragment> <Search color="disabled"/></React.Fragment>,
                                             sx: {
                                                 fontWeight: 'fontWeightBold',
                                                 '&.MuiFilledInput-root': {borderBottomLeftRadius: 8, borderBottomRightRadius: 8, paddingTop: 1.25},
                                                 '&.MuiFilledInput-root:before': {borderBottom: 0},
                                                 '&.MuiFilledInput-root.MuiInputBase-sizeSmall': {paddingBottom: 1},
                                             }
                                         }}
                              />}
            />

            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ml: 'auto', display: {xs: 'inline-flex', sm: 'none'}}}>
                    {!values.slideOpened && (
                        <IconButton aria-label="search" onClick={handleOpen}><Search/></IconButton>
                    )}
                    <Slide direction="down" in={values.slideOpened} mountOnEnter unmountOnExit>
                        <Box sx={{
                            position: 'absolute', top: 0, left: 0, width: '100%', height: 64, display: 'flex', alignItems: 'center', zIndex: 99,
                            backdropFilter: 'blur(6px)', WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                            padding: theme.spacing(0, 3),
                            boxShadow: theme.customShadows.z8,
                            backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
                            [theme.breakpoints.up('md')]: {
                                height: 92,
                                padding: theme.spacing(0, 5)
                            }
                        }}>
                            <Autocomplete freeSolo fullWidth options={values.options} groupBy={(option) => option.group} value={values.query} inputValue={values.inputQuery}
                                          onChange={(event, value) => {
                                              setValues((values) => ({...values, query: value}));
                                          }} onKeyDown={handleKeydown}
                                          onInputChange={(event, value) => {
                                              setValues((values) => ({...values, inputQuery: value}));
                                          }}
                                          renderInput={(params) =>
                                              <TextField {...params} placeholder={t("nav.search")} size='small'
                                                         InputProps={{
                                                             ...params.InputProps, autoFocus: true,
                                                             startAdornment: <React.Fragment> <Search color="disabled"/></React.Fragment>,
                                                             sx: {
                                                                 fontWeight: 'fontWeightBold',
                                                                 '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {border: 0}
                                                             }
                                                         }}
                                              />}
                            />
                        </Box>
                    </Slide>
                </Box>
            </ClickAwayListener>
        </>
    );
}

export default Searchbar;
