import React, {useEffect, useState} from 'react';
import {Card, CardActionArea, Container, LinearProgress, styled, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {AutoAwesome, SettingsAccessibility} from "@mui/icons-material";
import {alpha} from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import SecurityUtils from "../component/SecurityUtils";
import Utils from "../component/Utils";

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

const Home = (props) => {

    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        countProducts: undefined,
        countStories: undefined
    });

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/home/products/count", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, countProducts: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, message: error.response.data.message
                }));
            }
        );
        axios.get(Utils.getApiUrl() + "/home/stories/count", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, countStories: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    }, []);

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("nav.overview")} | {t("nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}

            <Typography variant="h4" gutterBottom>{t("nav.overview")}</Typography>

            <Grid container spacing={3} sx={{my: 5}}>
                {values.countProducts && <Grid item xs={12} sm={6} md={3}>
                    <CardActionArea component={RouterLink} to={"/home/products"} sx={{borderRadius: 2}}>
                        <Card sx={{
                            py: 5, boxShadow: 0, textAlign: 'center', color: (theme) => theme.palette["info"].darker,
                            bgcolor: (theme) => theme.palette["info"].lighter
                        }}>
                            <IconWrapperStyle sx={{
                                color: (theme) => theme.palette["info"].dark, backgroundImage: (theme) =>
                                    `linear-gradient(135deg, ${alpha(theme.palette["info"].dark, 0)} 0%, ${alpha(theme.palette["info"].dark, 0.24)} 100%)`
                            }}>
                                <AutoAwesome width={24} height={24}/>
                            </IconWrapperStyle>
                            <Typography variant="h3">{values.countProducts}</Typography>
                            <Typography variant="subtitle2" sx={{opacity: 0.72}}>{t("nav.product")}</Typography>
                        </Card>
                    </CardActionArea>
                </Grid>}
                {values.countStories && <Grid item xs={12} sm={6} md={3}>
                    <CardActionArea component={RouterLink} to={"/home/stories"} sx={{borderRadius: 2}}>
                        <Card sx={{
                            py: 5, boxShadow: 0, textAlign: 'center', color: (theme) => theme.palette["warning"].darker,
                            bgcolor: (theme) => theme.palette["warning"].lighter
                        }}>
                            <IconWrapperStyle sx={{
                                color: (theme) => theme.palette["warning"].dark, backgroundImage: (theme) =>
                                    `linear-gradient(135deg, ${alpha(theme.palette["warning"].dark, 0)} 0%, ${alpha(theme.palette["warning"].dark, 0.24)} 100%)`
                            }}>
                                <SettingsAccessibility width={24} height={24}/>
                            </IconWrapperStyle>
                            <Typography variant="h3">{values.countStories}</Typography>
                            <Typography variant="subtitle2" sx={{opacity: 0.72}}>{t("nav.story")}</Typography>
                        </Card>
                    </CardActionArea>
                </Grid>}
            </Grid>

        </Container>
    );
};

export default Home;
