import React, {useEffect, useState} from 'react';
import {
    Alert,
    Breadcrumbs,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import Utils from "../component/Utils";
import {Dashboard as DashboardIcon, Delete, Edit, FindInPage, Group, MoreVert, Search} from "@mui/icons-material";
import SecurityUtils from "../component/SecurityUtils";


const UsersAdmin = (props) => {

    const {t} = useTranslation(['user', 'admin']);
    const location = useLocation();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        params: '',
        page: undefined,
        id: '',
        anchorEl: null,
        dialogOpen: false
    });

    useEffect(() => {
        loadPage(location.pathname);
        setValues((values) => ({
            ...values, params: location.pathname
        }));
    }, [location.pathname]);

    const loadPage = async (params) => {
        await axios.get(Utils.getApiUrl() + params, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, page: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    const gotoPage = (event, number) => {
        let params = values.params;
        params = Utils.buildParams(params, "/page/", number);
        console.log(params);
        navigate(params);
    };

    const menuOpen = (event, id) => {
        setValues((values) => ({
            ...values, anchorEl: event.currentTarget, id: id
        }))
    };

    const dialogConfirmOpen = (id) => {
        setValues((values) => ({
            ...values, dialogOpen: true, anchorEl: null, id: id
        }))
    };

    const dialogConfirmClose = () => {
        setValues((values) => ({
            ...values, dialogOpen: false
        }));
    };

    const dialogConfirmDelete = async (id) => {
        setValues((values) => ({
            ...values, dialogOpen: false, loading: true
        }));
        await axios.delete(Utils.getApiUrl() + "/admin/user/" + id, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                console.log(response);
                loadPage(values.params);
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    const [filter, setFilter] = useState({
        modal: false,
        name: "",
        username: '',
        email: '',
        phone: ''
    })


    const handleFilterChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter((values) => ({
            ...values, [name]: value,
        }));
    };

    useEffect(() => {
        if (!values.params) return;
        const timeOutId = setTimeout(() => {
            let params = values.params;
            params = Utils.buildParams(params, "/name/", filter.name);
            loadPage(params);
            setValues((values) => ({
                ...values, params: params
            }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filter.name]);

    const modalFilterToggle = () => {
        setFilter((values) => ({
            ...values, modal: !filter.modal
        }));
    };

    const handleFilter = () => {
        let params = values.params;
        params = Utils.buildParams(params, "/username/", filter.username);
        params = Utils.buildParams(params, "/email/", filter.email);
        params = Utils.buildParams(params, "/phone/", filter.phone);
        setFilter((values) => ({
            ...values, modal: !filter.modal
        }));
        setValues((values) => ({
            ...values, params: params, loading: true
        }));
        loadPage(params);
    };

    const [sort, setSort] = useState({
        modal: false,
        name: '',
        username: '',
        email: '',
        phone: '',
        createdDate: ''
    })

    const modalSortToggle = () => {
        setSort((values) => ({
            ...values, modal: !sort.modal
        }));
    };

    const handleSortChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setSort((values) => ({
            ...values, [name]: value
        }));
    };

    const handleSort = () => {
        let params = values.params;
        params = Utils.buildSort(params, "/sort/name", sort.name);
        params = Utils.buildSort(params, "/sort/username", sort.username);
        params = Utils.buildSort(params, "/sort/email", sort.email);
        params = Utils.buildSort(params, "/sort/phone", sort.phone);
        setSort((values) => ({
            ...values, modal: !sort.modal
        }));
        setValues((values) => ({
            ...values, params: params, loading: true
        }));
        loadPage(params);
    };

    return (
        <Container>
            <Helmet>
                <title>{t("user:title")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("user:title")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home" sx={{display: 'flex', alignItems: 'center'}}>
                    <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                </Link>
                <Typography fontSize='small' sx={{display: 'flex', alignItems: 'center'}}>
                    <Group sx={{mr: 0.5}} fontSize="inherit"/> {t("user:title")}
                </Typography>
            </Breadcrumbs>

            <OutlinedInput name="name" startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
                           placeholder={t("search")} size='small' sx={{mt: 5, fontWeight: 'fontWeightBold'}}
                           value={filter.name} onChange={handleFilterChange}/>

            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}
            <TableContainer component={Paper} sx={{my: 5}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("user:name")}</TableCell>
                            <TableCell>{t("user:username")}</TableCell>
                            <TableCell>{t("user:email")}</TableCell>
                            <TableCell>{t("user:phone")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.page && values.page.content && values.page.content.map(user =>
                            <TableRow key={user.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{user.name}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phone}</TableCell>
                                <TableCell>
                                    <IconButton onClick={(event) => menuOpen(event, user.id)}><MoreVert/></IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {!values.loading && values.page.totalElements === 0 &&
            <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5} color="text.secondary">
                <FindInPage fontSize='large'/> {t("translation:view.empty")}
            </Typography>}
            {!values.loading && values.page.totalElements !== 0 && <>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
                    <Pagination count={values.page.totalPages} variant="outlined" color="primary" onChange={gotoPage}/>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
                    <Typography>{t("translation:view.total", {
                        totalElements: values.page.totalElements,
                        pageNumber: values.page.number,
                        totalPages: values.page.totalPages
                    })}</Typography>
                </Stack>
            </>}
            <Menu open={Boolean(values.anchorEl)} anchorEl={values.anchorEl}
                  onClose={() => setValues((values) => ({...values, anchorEl: null, id: ''}))}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  PaperProps={{
                      elevation: 0, sx: {
                          width: 150, maxWidth: '100%', overflow: 'visible', mt: 1, filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          '&:before': {
                              content: '""', display: 'block', position: 'absolute', top: 0, right: 15, width: 15, height: 15,
                              bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,
                          }
                      },
                  }}>
                <MenuItem component={RouterLink} to={"/admin/user/" + values.id} sx={{color: 'text.secondary'}}>
                    <ListItemIcon><Edit/></ListItemIcon>
                    <ListItemText primary={t("admin:edit")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                <MenuItem onClick={() => dialogConfirmOpen(values.id)} sx={{color: 'text.secondary'}}>
                    <ListItemIcon><Delete/></ListItemIcon>
                    <ListItemText primary={t("admin:delete")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>
            <Dialog open={values.dialogOpen} maxWidth="sm" fullWidth
                    onClose={() => dialogConfirmClose()}>
                <DialogTitle>{t("admin:confirm")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("admin:confirmDeleteTitle")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dialogConfirmClose()}>{t("admin:cancel")}</Button>
                    <Button onClick={() => dialogConfirmDelete(values.id)} autoFocus>{t("admin:confirm")}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UsersAdmin;
