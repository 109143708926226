import React, {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    LinearProgress,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import axios from "axios";
import SecurityUtils from "./component/SecurityUtils";
import Utils from "./component/Utils";
import Grid from "@mui/material/Unstable_Grid2";
import {NumericFormat} from "react-number-format";
import {ReadMore} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";

const Index = (props) => {

    const {t} = useTranslation('index');
    const theme = useTheme();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        pageLatest: undefined,
        pageMostViewed: undefined
    });

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/products/size/12", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, pageLatest: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
        axios.get(Utils.getApiUrl() + "/products/size/12/sort/views,desc", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, pageMostViewed: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    }, []);


    return (
        <Container component='main'>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Paper elevation={8} sx={{
                my: 5,
                p: 5,
                background: `url(/img/banner.jpg) center center no-repeat`,
                backgroundSize: 'cover',
                color: 'white',
                textAlign: 'center'
            }}>
                <Typography variant="h4">{t("title")}</Typography>
            </Paper>

            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            {values.pageLatest && values.pageLatest.content && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                    <Typography variant="h4" gutterBottom>{t("translation:view.latest")}</Typography>
                    <Button component={RouterLink} to="/products"
                            endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                </Stack></>}
            <Grid container spacing={3} sx={{mb: 5}}>
                {values.pageLatest && values.pageLatest.content && values.pageLatest.content.map(entity =>
                    <Grid key={entity.id} item xs={6} sm={4} md={3}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/product/" + entity.id}>
                                <CardMedia component="img" height="150" alt={entity.name} image={entity.pictures ?
                                    Utils.getApiUrl() + "/product/" + entity.id + "/picture/" + (entity.pictures.split(',')[0])
                                    : "/img/banner.jpg"}/>
                                <Box component="span" sx={{
                                    display: 'inline-block',
                                    color: 'background.paper',
                                    bgcolor: 'currentColor',
                                    mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    position: 'absolute',
                                    left: 0,
                                    top: 130,
                                    width: 80,
                                    height: 36
                                }}/>
                                <Avatar src={entity.user.photo && Utils.getApiUrl() + "/user/" + entity.user.photo
                                    + "?" + Math.random()} alt={entity.user.name} sx={{
                                    position: 'absolute',
                                    left: theme.spacing(3),
                                    top: 135,
                                    width: 32,
                                    height: 32,
                                    bgcolor: theme.palette.primary.main
                                }}/>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom noWrap>{entity.name}</Typography>
                                    <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                        {entity.price && (<><NumericFormat value={entity.price} displayType={'text'}
                                                                           thousandSeparator prefix={'¥'}/></>)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {values.pageMostViewed && values.pageMostViewed.content && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                    <Typography variant="h4" gutterBottom>{t("translation:view.mostViewed")}</Typography>
                    <Button component={RouterLink} to="/products"
                            endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                </Stack></>}
            <Grid container spacing={3} sx={{mb: 5}}>
                {values.pageMostViewed && values.pageMostViewed.content && values.pageMostViewed.content.map(entity =>
                    <Grid key={entity.id} item xs={6} sm={4} md={3}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/product/" + entity.id}>
                                <CardMedia component="img" height="150" alt={entity.name} image={entity.pictures ?
                                    Utils.getApiUrl() + "/product/" + entity.id + "/picture/" + (entity.pictures.split(',')[0])
                                    : "/img/banner.jpg"}/>
                                <Box component="span" sx={{
                                    display: 'inline-block',
                                    color: 'background.paper',
                                    bgcolor: 'currentColor',
                                    mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    position: 'absolute',
                                    left: 0,
                                    top: 130,
                                    width: 80,
                                    height: 36
                                }}/>
                                <Avatar src={entity.user.photo && Utils.getApiUrl() + "/user/" + entity.user.photo
                                    + "?" + Math.random()} alt={entity.user.name} sx={{
                                    position: 'absolute',
                                    left: theme.spacing(3),
                                    top: 135,
                                    width: 32,
                                    height: 32,
                                    bgcolor: theme.palette.primary.main
                                }}/>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom noWrap>{entity.name}</Typography>
                                    <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                        {entity.price && (<><NumericFormat value={entity.price} displayType={'text'}
                                                                           thousandSeparator prefix={'¥'}/></>)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default Index;
