import React, {useState} from 'react';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import SecurityUtils from "../component/SecurityUtils";

const LANGS = [
    {
        value: 'en',
        label: 'English',
        icon: '/img/icon_flag_en.svg',
    },
    {
        value: 'zh',
        label: '中文',
        icon: '/img/icon_flag_zh.svg',
    }
];

const LanguageMenu = (props) => {

    const {t, i18n} = useTranslation();
    const [values, setValues] = useState({
        user: SecurityUtils.getCurrentUser(),
        anchorEl: null,
        currentLanguage: i18n.language === 'en' ? LANGS[0] : LANGS[1]
    });

    const handleMenuOpen = (event) => {
        setValues((values) => ({
            ...values, anchorEl: event.currentTarget
        }))
    };

    const handleMenuClose = () => {
        setValues((values) => ({
            ...values, anchorEl: null
        }))
    };

    const changeLanguage = (language) => {
        setValues((values) => ({
            ...values, anchorEl: null, currentLanguage: language === 'en' ? LANGS[0] : LANGS[1]
        }))
        i18n.changeLanguage(language);
    }

    return (
        <>
            <IconButton onClick={handleMenuOpen} sx={{ml: "auto"}} size="large">
                <Box component="img" alt={values.currentLanguage.label} src={values.currentLanguage.icon} sx={{width: 28}}/>
            </IconButton>
            <Menu open={Boolean(values.anchorEl)} anchorEl={values.anchorEl} onClose={handleMenuClose} onClick={handleMenuClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  PaperProps={{
                      elevation: 0, sx: {
                          width: 150, maxWidth: '100%', overflow: 'visible', mt: 1, filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          '&:before': {
                              content: '""', display: 'block', position: 'absolute', top: 0, right: 15, width: 15, height: 15,
                              bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,
                          }
                      },
                  }}>
                {LANGS.map(option => (
                    <MenuItem key={option.value} onClick={() => changeLanguage(option.value)}>
                        <ListItemIcon>
                            <Box component="img" alt={option.label} src={option.icon} sx={{width: 28}}/>
                        </ListItemIcon>
                        <ListItemText primary={option.label}/>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LanguageMenu;
