import {Link as RouterLink, Outlet} from 'react-router-dom';
import {AppBar, Box, Button, Link, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import Searchbar from "./Searchbar";
import Nav from "./Nav";
import Logo from "./Logo";
import {useTranslation} from "react-i18next";
import AvatarMenu from "./AvatarMenu";
import LanguageMenu from "./LanguageMenu";
import SecurityUtils from "../component/SecurityUtils";

function Main(props) {

    const {t} = useTranslation();
    const [values, setValues] = useState({
        user: SecurityUtils.getCurrentUser()
    });

    return (
        <>
            <AppBar position="relative" color="transparent" elevation={0}
                    sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: "text.secondary", p: 1}}>
                <Logo/>
                <Searchbar/>
                <Box display="inline-flex" alignItems="center">
                    <LanguageMenu/>
                    {values.user ? <AvatarMenu/> : <Button component={RouterLink} to="/login">{t("nav.signin")}</Button>}
                </Box>
            </AppBar>
            <Nav/>
            <Outlet/>
            <Stack component="footer" direction="row" spacing={2} justifyContent="center" alignItems="center" my={5} color="text.secondary">
                <Typography>Victz &copy; {new Date().getFullYear()}</Typography>
                <Link component={RouterLink} to="/contact" underline="none" color="text.secondary">{t("nav.contactus")}</Link>
            </Stack>
        </>
    );
};
export default Main;
