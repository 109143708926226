import React, {useEffect, useState} from 'react';
import {
    Alert,
    Avatar,
    Box,
    Breadcrumbs,
    ButtonBase,
    Card,
    CardActionArea,
    CardMedia,
    Container,
    FormHelperText,
    LinearProgress,
    Link,
    TextField,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import SecurityUtils from "../component/SecurityUtils";
import {useTheme} from "@mui/material/styles";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import {AddAPhoto, Dashboard as DashboardIcon, Group, ManageAccounts} from "@mui/icons-material";
import Utils from "../component/Utils";

const Account = (props) => {

    const {t} = useTranslation(['user', 'admin']);
    const theme = useTheme();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        nameError: false,
        nameValidate: '',
        usernameError: false,
        usernameValidate: ''
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        username: '',
        email: '',
        phone: '',
        photo: '',
        cover: '',
        locale: '',
        emailVerified: false,
        phoneVerified: false,
        roles: []
    });

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/account", {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    for (const [name, value] of Object.entries(response.data)) {
                        if (value === null) continue;
                        if (model.hasOwnProperty(name)) {
                            setModel((values) => ({
                                ...values, [name]: value
                            }));
                        }
                    }
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            ).finally(() => {
            setValues((values) => ({
                ...values, loading: false
            }));
        });
    }, [])

    const handlePhoto = async (event) => {

        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append('photo', event.target.files[0]);

        await axios.post(Utils.getApiUrl() + "/account/photo", formData, {
            headers: {
                "Authorization": SecurityUtils.getBearerToken(),
                "Content-Type": "multipart/form-data"
            }, processData: false, contentType: false,
            onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        }).then((response) => {
                console.log(response);
                setModel((values) => ({
                    ...values, photo: response.data.photo
                }));
                setValues((values) => ({
                    ...values, loading: false
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        )
    }

    const handleCover = async (event) => {

        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append('cover', event.target.files[0]);

        await axios.post(Utils.getApiUrl() + "/account/cover", formData, {
            headers: {
                "Authorization": SecurityUtils.getBearerToken(),
                "Content-Type": "multipart/form-data"
            }, processData: false, contentType: false,
            onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        }).then((response) => {
                console.log(response);
                setModel((values) => ({
                    ...values, cover: response.data.cover
                }));
                setValues((values) => ({
                    ...values, loading: false
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        )
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setModel((values) => ({
            ...values, [name]: value
        }));
        if (name === "name") {
            if (value.trim().length > 1) {
                setValues((values) => ({
                    ...values, nameError: false, nameValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, nameError: true, nameValidate: t("user:nameValidate")
                }));
            }
        } else if (name === "username") {
            if (value.trim().length > 1) {
                setValues((values) => ({
                    ...values, usernameError: false, usernameValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, usernameError: true, usernameValidate: t("user:usernameValidate")
                }));
            }
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!(event.target.elements["name"].value.trim().length > 1)) {
            setValues((values) => ({
                ...values, nameError: true, nameValidate: t("user:nameValidate")
            }));
            return false;
        }
        if (!(event.target.elements["username"].value.trim().length > 1)) {
            setValues((values) => ({
                ...values, nameError: true, nameValidate: t("user:usernameValidate")
            }));
            return false;
        }

        setValues((values) => ({
            ...values, loading: true
        }));

        await axios.post(Utils.getApiUrl() + "/account", model, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    SecurityUtils.logout();
                    navigate('/info', {
                        state: {
                            alert: "success",
                            summary: t("messages.summary"),
                            details: t("messages.details")
                        }
                    });
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            );
    }

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("translation:nav.account")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("translation:nav.account")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"
                      sx={{display: 'flex', alignItems: 'center'}}>
                    <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                </Link>
                <Typography fontSize='inherit' sx={{display: 'flex', alignItems: 'center'}}>
                    <ManageAccounts sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.account")}
                </Typography>
            </Breadcrumbs>

            <Card sx={{mt: 5, textAlign: 'center'}}>
                <CardActionArea focusRipple sx={{
                    position: "relative", overflow: "hidden",
                    '&:hover, &.Mui-focusVisible': {
                        '& .MuiCardActionArea-focusHighlight': {opacity: 0.5},
                        '& .MuiSvgIcon-root': {opacity: 1, zIndex: 1},
                        '& .MuiTypography-root': {opacity: 1, zIndex: 1}
                    },
                }}>
                    <CardMedia component="div" sx={{
                        height: 200,
                        backgroundImage: model.cover ? "url(" + Utils.getApiUrl() + "/user/" + model.cover
                            + "?" + Math.random() + ")" : "url(/img/banner.jpg)",
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.palette.common.white,
                        [theme.breakpoints.down('sm')]: {
                            width: '100% !important', height: 150
                        },
                    }}>
                        <AddAPhoto fontSize='large' sx={{opacity: 0, mb: 1}}/>
                        <Typography component="span" sx={{opacity: 0}}>{t("user:updateCover")}</Typography>
                    </CardMedia>
                    <Box component='input' type="file" accept="image/*" onChange={handleCover} value="" sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        m: 0,
                        p: 0,
                        cursor: 'pointer',
                        opacity: 0,
                        zIndex: 10
                    }}/>
                </CardActionArea>
                <ButtonBase focusRipple sx={{
                    position: "relative", overflow: "hidden", mt: -6,
                    '&:hover, &.Mui-focusVisible': {
                        '& .MuiImageBackdrop-root': {opacity: 0.5},
                        '& .MuiImageMarked-root': {opacity: 1},
                    },
                }}>
                    <Avatar sx={{bgcolor: theme.palette.primary.main, width: 150, height: 150}} alt={model.name}
                            src={model.photo && Utils.getApiUrl() + "/user/" + model.photo + "?" + Math.random()}/>
                    <Box component="span" className="MuiImageBackdrop-root" sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        m: 0,
                        p: 0,
                        backgroundColor: theme.palette.common.black,
                        borderRadius: '50%',
                        opacity: 0,
                        transition: theme.transitions.create('opacity'),
                    }}/>
                    <Box component="div" className="MuiImageMarked-root" sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        m: 0,
                        p: 0,
                        opacity: 0,
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.palette.common.white
                    }}>
                        <AddAPhoto fontSize='large' sx={{mb: 1}}/>
                        <Typography component="span">{t("user:updatePhoto")}</Typography>
                    </Box>
                    <Box component='input' type="file" accept="image/*" onChange={handlePhoto} value="" sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        m: 0,
                        p: 0,
                        cursor: 'pointer',
                        opacity: 0
                    }}/>
                </ButtonBase>
            </Card>

            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                <TextField name="name" label={t("user:name")} margin="normal" variant="standard" fullWidth required
                           value={model.name} onChange={handleChange} error={values.nameError}
                           helperText={values.nameValidate}/>
                <TextField name="username" label={t("user:username")} margin="normal" variant="standard" fullWidth
                           required
                           value={model.username} onChange={handleChange} error={values.usernameError}
                           helperText={values.usernameValidate}/>

                <TextField name="email" label={t("user:email")} value={model.email} variant="standard" margin="normal"
                           fullWidth disabled/>
                <FormHelperText>{model.emailVerified ? t("user:emailVerified") : t("user:emailNotVerified")}</FormHelperText>

                <TextField name="phone" label={t("user:phone")} margin="normal" variant="standard" fullWidth
                           value={model.phone} disabled/>
                <FormHelperText>{model.phoneVerified ? t("user:phoneVerified") : t("user:phoneNotVerified")}</FormHelperText>

                <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                               sx={{my: 3}}>{t("admin:save")}</LoadingButton>
            </Box>
        </Container>
    );
};

export default Account;
