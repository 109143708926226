import {Link as RouterLink, Outlet, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {AppBar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, useMediaQuery} from "@mui/material";
import Searchbar from "./Searchbar";
import {alpha, useTheme} from "@mui/material/styles";
import Nav from "./Nav";
import {AutoAwesome, Dashboard as DashboardIcon, Group, ManageAccounts, Menu, PriceCheck, Receipt, SettingsAccessibility} from "@mui/icons-material";
import Logo from "./Logo";
import AvatarMenu from "./AvatarMenu";
import {useTranslation} from "react-i18next";
import LanguageMenu from "./LanguageMenu";
import SecurityUtils from "../component/SecurityUtils";

const Dashboard = (props) => {

    const {t} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

    const [values, setValues] = useState({
        user: SecurityUtils.getCurrentUser()
    });

    const [drawerOpened, setDrawerOpened] = useState(!isMobile);
    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    };
    useEffect(() => {
        setDrawerOpened(!isMobile);
    }, [isMobile]);

    const {pathname} = useLocation();
    useEffect(() => {
        if (isMobile && drawerOpened) {
            toggleDrawer();
        }
    }, [pathname]);

    const styleDrawer = {
        '& .MuiDrawer-paper': {
            width: 260,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('sm')]: {
                top: '118px'
            }
        }
    };

    const styleListItem = {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        textTransform: 'capitalize',
        height: 48,
        borderRadius: 1,
        m: 1,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2.5)
    };

    const styleListItemSelected = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
    };

    const styleMain = {
        py: 3,
        ...(!isMobile && drawerOpened && {
            marginLeft: 32.5,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        }),
        ...(!isMobile && !drawerOpened && {
            marginLeft: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        }),
    };

    return (
        <>
            <AppBar position="relative" color="transparent" elevation={0}
                    sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: "text.secondary", px: {sm: 2}}}>
                <Box display="inline-flex" alignItems="center">
                    <IconButton aria-label="menu" onClick={toggleDrawer}>
                        <Menu/>
                    </IconButton>
                    <Logo/>
                </Box>
                <Searchbar/>
                <Box display="inline-flex" alignItems="center">
                    <LanguageMenu/>
                    <AvatarMenu/>
                </Box>
            </AppBar>
            <Nav/>
            <Drawer variant={isMobile ? 'temporary' : 'persistent'} open={drawerOpened} onClose={toggleDrawer} ModalProps={{keepMounted: true}} sx={styleDrawer}>
                <Box sx={{display: {xs: 'inline-flex', sm: 'none'}, p: 2}}><Logo/></Box>
                <List>
                    <ListItemButton disableGutters component={RouterLink} to="/home" selected={pathname === '/home'}
                                    sx={{...(styleListItem), ...(pathname === '/home' && styleListItemSelected)}}>
                        <ListItemIcon><DashboardIcon/></ListItemIcon>
                        <ListItemText primary={t("nav.overview")}/>
                    </ListItemButton>
                    {/*<ListItemButton disableGutters component={RouterLink} to="/home/purchases" selected={pathname === '/home/purchases'}*/}
                    {/*                sx={{...(styleListItem), ...(pathname === '/home/purchases' && styleListItemSelected)}}>*/}
                    {/*    <ListItemIcon><Receipt/> </ListItemIcon>*/}
                    {/*    <ListItemText primary={t("nav.purchases")}/>*/}
                    {/*</ListItemButton>*/}
                    <ListItemButton disableGutters component={RouterLink} to="/home/stories" selected={pathname === '/home/stories'}
                                    sx={{...(styleListItem), ...(pathname === '/home/stories' && styleListItemSelected)}}>
                        <ListItemIcon><SettingsAccessibility/></ListItemIcon>
                        <ListItemText primary={t("nav.story")}/>
                    </ListItemButton>
                    <ListItemButton disableGutters component={RouterLink} to="/home/products" selected={pathname === '/home/products'}
                                    sx={{...(styleListItem), ...(pathname === '/home/products' && styleListItemSelected)}}>
                        <ListItemIcon><AutoAwesome/></ListItemIcon>
                        <ListItemText primary={t("nav.product")}/>
                    </ListItemButton>
                    {/*<ListItemButton disableGutters component={RouterLink} to="/home/sales" selected={pathname === '/home/sales'}*/}
                    {/*                sx={{...(styleListItem), ...(pathname === '/home/sales' && styleListItemSelected)}}>*/}
                    {/*    <ListItemIcon><PriceCheck/></ListItemIcon>*/}
                    {/*    <ListItemText primary={t("nav.sales")}/>*/}
                    {/*</ListItemButton>*/}
                    <ListItemButton disableGutters component={RouterLink} to="/home/account" selected={pathname === '/home/account'}
                                    sx={{...(styleListItem), ...(pathname === '/home/account' && styleListItemSelected)}}>
                        <ListItemIcon><ManageAccounts/></ListItemIcon>
                        <ListItemText primary={t("nav.account")}/>
                    </ListItemButton>
                    {values.user.roles.includes("ROLE_ADMIN") && <>
                        <Divider/>
                        <ListSubheader sx={{ml: 1}}>{t("nav.admin")}</ListSubheader>
                        <ListItemButton disableGutters component={RouterLink} to="/admin/users" selected={pathname === '/admin/users'}
                                        sx={{...(styleListItem), ...(pathname === '/admin/users' && styleListItemSelected)}}>
                            <ListItemIcon><Group/></ListItemIcon>
                            <ListItemText primary={t("nav.user")}/>
                        </ListItemButton>
                    </>}
                </List>
            </Drawer>
            <Box component="main" sx={styleMain}>
                <Outlet/>
            </Box>
        </>
    );
};

export default Dashboard;
