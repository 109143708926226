import React, {useState} from 'react';
import {Avatar, Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import {Dashboard, Logout, ManageAccounts, Receipt, SettingsAccessibility} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import SecurityUtils from "../component/SecurityUtils";
import PasswordIcon from '@mui/icons-material/Password';
import Utils from "../component/Utils";

const AvatarMenu = (props) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        user: SecurityUtils.getCurrentUser(),
        anchorEl: null
    });

    const handleMenuOpen = (event) => {
        setValues((values) => ({
            ...values, anchorEl: event.currentTarget
        }))
    };
    const handleMenuClose = () => {
        setValues((values) => ({
            ...values, anchorEl: null
        }))
    };

    const logout = () => {
        setValues((values) => ({
            ...values, user: undefined
        }));
        SecurityUtils.logout();
        navigate('/login', {replace: true});
    }

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <Avatar sx={{bgcolor: theme.palette.primary.main}} alt={values.user.name}
                        src={values.user.photo ? Utils.getApiUrl() + "/user/" + values.user.photo
                            + "?" + Math.random() : "/img/avatar_default.jpg"}/>
            </IconButton>
            <Menu anchorEl={values.anchorEl} open={Boolean(values.anchorEl)} onClose={handleMenuClose}
                  onClick={handleMenuClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  PaperProps={{
                      elevation: 0, sx: {
                          width: 220, overflow: 'visible', mt: 1, filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 20,
                              width: 15,
                              height: 15,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          }
                      },
                  }}>
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="h6" noWrap>{values.user.name}</Typography>
                </Box>
                <Divider sx={{my: 1}}/>
                <MenuItem component={RouterLink} to="/home">
                    <ListItemIcon><Dashboard/></ListItemIcon>
                    <ListItemText primary={t("nav.dashboard")}/>
                </MenuItem>
                {/*<MenuItem component={RouterLink} to="/home/purchases">*/}
                {/*    <ListItemIcon><Receipt/></ListItemIcon>*/}
                {/*    <ListItemText primary={t("nav.purchases")}/>*/}
                {/*</MenuItem>*/}
                <MenuItem component={RouterLink} to="/home/stories">
                    <ListItemIcon><SettingsAccessibility/></ListItemIcon>
                    <ListItemText primary={t("nav.story")}/>
                </MenuItem>
                <MenuItem component={RouterLink} to="/home/account">
                    <ListItemIcon><ManageAccounts/></ListItemIcon>
                    <ListItemText primary={t("nav.account")}/>
                </MenuItem>
                <Divider/>
                <MenuItem component={RouterLink} to="/home/password">
                    <ListItemIcon><PasswordIcon/></ListItemIcon>
                    <ListItemText primary={t("nav.password")}/>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon><Logout/></ListItemIcon>
                    <ListItemText primary={t("nav.signout")}/>
                </MenuItem>
            </Menu>
        </>
    );
};

export default AvatarMenu;
