import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Alert, Box, Container, Paper, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import Utils from "./component/Utils";

const Activate = (props) => {

    const {key} = useParams();
    const {t} = useTranslation('activate');
    const [values, setValues] = useState({
        alert: '',
        details: ''
    });

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/activate/" + key).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, alert: "success", details: t("message")
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, alert: "error", details: error.response.data.message
                }));
            }
        );
    }, [])

    return (
        <>
            <Container component='main'>
                <Helmet>
                    <title>{t("title")} | {t("translation:nav.brand")}</title>
                </Helmet>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{t("title")}</Typography>
                </Paper>
                {values.alert && (
                    <Box maxWidth="sm">
                        <Alert severity={values.alert}>
                            {values.details}
                        </Alert>
                    </Box>
                )}
            </Container>
        </>
    );
};

export default Activate;
