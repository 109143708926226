import React, {useState} from "react";
import {Alert, Box, Container, FormHelperText, Paper, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import SecurityUtils from "./component/SecurityUtils";
import {Helmet} from "react-helmet-async";
import Utils from "./component/Utils";

const Forgot = (props) => {

    const {t} = useTranslation("forgot");
    const navigate = useNavigate();

    const patternEmail = /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

    const [values, setValues] = useState({
        loading: false,
        message: '',
        email: '',
        emailError: false,
        emailValidate: ''
    });

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setValues((values) => ({
            ...values, [name]: value,
        }));
        if (name === "email") {
            if (value.trim() !== "" && patternEmail.test(value.trim())) {
                setValues((values) => ({
                    ...values, emailError: false, emailValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, emailError: true, emailValidate: t("form.emailValidate")
                }));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!(event.target.elements["email"].value.trim() !== "" && patternEmail.test(event.target.elements["email"].value.trim()))) {
            setValues((values) => ({
                ...values, emailError: true, emailValidate: t("form.emailValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append("email", values.email);

        axios.post(Utils.getApiUrl() + "/forgot", formData, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                console.log(response);
                navigate('/info', {
                    state: {
                        alert: "success",
                        summary: t("messages.summary"),
                        details: t("messages.details")
                    }
                });
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    return (
        <>
            <Container component="main">
                <Helmet>
                    <title>{t("title")} | {t("translation:nav.brand")}</title>
                </Helmet>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{t("title")}</Typography>
                </Paper>

                <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                    {values.message && (
                        <Alert severity="error">{values.message}</Alert>
                    )}

                    <TextField name="email" label={t("form.email")} margin="normal" fullWidth required
                               value={values.email} onChange={handleChange} error={values.emailError} helperText={values.emailValidate}/>
                    <FormHelperText>{t("form.emailHelp")}</FormHelperText>

                    <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                   sx={{mt: 3, mb: 2}}>{t("form.button")}</LoadingButton>
                </Box>
            </Container>
        </>
    );
};

export default Forgot;
