import React from "react";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import {Navigate, Route, Routes} from "react-router-dom";
import Main from "./layout/Main";
import Index from "./Welcome";
import Stories from "./Stories";
import Products from "./Products";
import Contact from "./Contact";
import Dashboard from "./layout/Dashboard";
import Home from "./home";
import Purchases from "./home/Purchases";
import Account from "./home/Account";
import UsersAdmin from "./admin/Users";
import Login from "./Login";
import Register from "./Register";
import Info from "./component/Info";
import Activate from "./Activate";
import PrivateRoute from "./component/PrivateRoute";
import AdminRoute from "./component/AdminRoute";
import UserAdmin from "./admin/User";
import ProductsHome from "./home/Products";
import StoriesHome from "./home/Stories";
import Sales from "./home/Sales";
import ProductHome from "./home/Product";
import Product from "./Product";
import StoryHome from "./home/Story";
import Story from "./Story";
import {useTranslation} from "react-i18next";
import Forgot from "./Forgot";
import Reset from "./Reset";
import Password from "./home/Password";
import Users from "./Users";
import Search from "./Search";
import User from "./user";
import UserProducts from "./user/Products";
import UserStories from "./user/Stories";

function App() {

    const { t} = useTranslation();

    return (
        <ThemeConfig>
            <GlobalStyles/>
            <Routes>
                <Route path="/home" element={<PrivateRoute><Dashboard/></PrivateRoute>}>
                    <Route index element={<Home/>}/>
                    <Route path="purchases/*" element={<Purchases/>}/>
                    <Route path="stories/*" element={<StoriesHome/>}/>
                    <Route path='story' element={<StoryHome/>}>
                        <Route path=':id' element={<StoryHome/>}/>
                    </Route>
                    <Route path="products/*" element={<ProductsHome/>}/>
                    <Route path='product' element={<ProductHome/>}>
                        <Route path=':id' element={<ProductHome/>}/>
                    </Route>
                    <Route path="sales/*" element={<Sales/>}/>
                    <Route path="account" element={<Account/>}/>
                    <Route path="password" element={<Password/>}/>
                </Route>
                <Route path="/admin" element={<AdminRoute><Dashboard/></AdminRoute>}>
                    <Route path="users/*" element={<UsersAdmin/>}/>
                    <Route path='user' element={<UserAdmin/>}>
                        <Route path=':id' element={<UserAdmin/>}/>
                    </Route>
                </Route>
                <Route path="/" element={<Main/>}>
                    <Route index element={<Index/>}/>
                    <Route path="index" element={<Index/>}/>
                    <Route path="products/*" element={<Products/>}/>
                    <Route path='product/:id' element={<Product/>}/>
                    <Route path="stories/*" element={<Stories/>}/>
                    <Route path='story/:id' element={<Story/>}/>
                    <Route path="users/*" element={<Users/>}/>
                    <Route path='user/:username' element={<User/>}/>
                    <Route path='user/:username/products/*' element={<UserProducts/>}/>
                    <Route path='user/:username/stories/*' element={<UserStories/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="register" element={<Register/>}/>
                    <Route path='activate/:key' element={<Activate/>}/>
                    <Route path='info' element={<Info/>}/>
                    <Route path='forgot' element={<Forgot/>}/>
                    <Route path='reset/:key' element={<Reset/>}/>
                    <Route path='search/*' element={<Search/>}/>
                    <Route path="*" element={<Navigate to="/info" state={{alert: "error", summary: t("error.title"), details: t("error.404")}}/>}/>
                </Route>
            </Routes>
        </ThemeConfig>
    );
}

export default App;
