import React, {useEffect, useState} from "react";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import SecurityUtils from "./component/SecurityUtils";
import {Helmet} from "react-helmet-async";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    LinearProgress,
    Stack,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {NumericFormat} from "react-number-format";
import {FindInPage, ReadMore} from "@mui/icons-material";
import {format, parseISO} from "date-fns";
import {useTheme} from "@mui/material/styles";
import Utils from "./component/Utils";

const Search = (props) => {

    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        params: '',
        pageProducts: undefined,
        pageUsers: undefined,
        pageStories: undefined
    });

    useEffect(() => {
        let params = location.pathname.slice(8);
        console.log(params);
        loadPage(params);
        setValues((values) => ({
            ...values, params: params
        }));
    }, [location.pathname]);

    const loadPage = async (params) => {
        await axios.get(Utils.getApiUrl() + "/products/size/12/name/" + params, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    setValues((values) => ({
                        ...values, pageProducts: response.data
                    }));
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, message: error.response.data.message
                    }));
                }
            );
        await axios.get(Utils.getApiUrl() + "/users/size/12/name/" + params, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    setValues((values) => ({
                        ...values, pageUsers: response.data
                    }));
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, message: error.response.data.message
                    }));
                }
            );
        await axios.get(Utils.getApiUrl() + "/stories/size/12/title/" + params, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    setValues((values) => ({
                        ...values, pageStories: response.data
                    }));
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, message: error.response.data.message
                    }));
                }
            );
        setValues((values) => ({
            ...values, loading: false
        }));
    };

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("nav.search")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary"/>}
            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}
            {!values.loading && values.pageProducts.totalElements === 0 && values.pageUsers.totalElements === 0 && values.pageStories.totalElements === 0 &&
                <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                            color="text.secondary">
                    <FindInPage fontSize='large'/> {t("view.empty")}
                </Typography>}

            {values.pageProducts && values.pageProducts.content && values.pageProducts.totalElements > 0 && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <Typography variant="h4">{t("nav.search")}{t("nav.product")}</Typography>
                    <Button component={RouterLink} to={"/products/name/" + values.params}
                            endIcon={<ReadMore/>}>{t("view.all")}</Button>
                </Stack></>}

            <Grid container spacing={3} sx={{mt: 3}}>
                {values.pageProducts && values.pageProducts.content && values.pageProducts.content.map(product =>
                    <Grid key={product.id} item xs={6} sm={4} md={3}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/product/" + product.id}>
                                <CardMedia component="img" height="150" image={product.pictures ?
                                    Utils.getApiUrl() + "/product/" + product.id + "/picture/" + (product.pictures.split(',')[0]).replace('.', '_thumbnail.')
                                    : "/img/banner.jpg"}
                                />
                                <CardContent>
                                    <Typography noWrap gutterBottom>{product.name}</Typography>
                                    <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                        {product.price && (<><NumericFormat value={product.price} displayType={'text'}
                                                                            thousandSeparator prefix={'¥'}/></>)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {values.pageUsers && values.pageUsers.content && values.pageUsers.totalElements > 0 && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <Typography variant="h4">{t("nav.search")}{t("nav.users")}</Typography>
                    <Button component={RouterLink} to={"/users/name/" + values.params}
                            endIcon={<ReadMore/>}>{t("view.all")}</Button>
                </Stack></>}

            <Grid container spacing={3} sx={{mt: 3}}>
                {values.pageUsers && values.pageUsers.content && values.pageUsers.content.map(entity =>
                    <Grid key={entity.id} item xs={12} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/user/" + entity.username}>
                                <CardMedia component="div" sx={{
                                    height: 200,
                                    backgroundImage: entity.cover ? "url(" + Utils.getApiUrl() + "/user/" + entity.cover
                                        + "?" + Math.random() + ")" : "url(/img/banner.jpg)",
                                    display: 'flex',
                                    alignItems: "flex-end",
                                    justifyContent: 'center'
                                }}>
                                    <Box component="span" sx={{
                                        display: 'inline-block',
                                        color: 'background.paper',
                                        bgcolor: 'currentColor',
                                        mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        position: 'absolute',
                                        left: 'calc(100% * 1 /2) - 100',
                                        top: 164,
                                        width: 144,
                                        height: 62
                                    }}/>
                                    <Avatar src={entity.photo && Utils.getApiUrl() + "/user/" + entity.photo
                                        + "?" + Math.random()} alt={entity.name} sx={{
                                        bgcolor: theme.palette.primary.main, width: 64, height: 64, mb: -4
                                    }}/>
                                </CardMedia>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom>{entity.name}</Typography>
                                    <Typography variant="caption" noWrap gutterBottom
                                                sx={{color: 'text.disabled', display: 'block'}}>
                                        {format(parseISO(entity.createdDate), 'yyyy-MM-dd')}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {values.pageStories && values.pageStories.content && values.pageStories.totalElements > 0 && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <Typography variant="h4">{t("nav.search")}{t("nav.story")}</Typography>
                    <Button component={RouterLink} to={"/stories/title/" + values.params}
                            endIcon={<ReadMore/>}>{t("view.all")}</Button>
                </Stack></>}

            <Grid container spacing={3} sx={{mt: 3}}>
                {values.pageStories && values.pageStories.content && values.pageStories.content.map(entity =>
                    <Grid key={entity.id} item xs={12} sm={6} md={3}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/story/" + entity.id}>
                                <CardMedia component="img" height="150" alt={entity.name} image={entity.pictures ?
                                    Utils.getApiUrl() + "/story/" + entity.id + "/picture/" + (entity.pictures.split(',')[0]).replace('.', '_thumbnail.')
                                    : "/img/banner.jpg"}/>
                                <Box component="span" sx={{
                                    display: 'inline-block',
                                    color: 'background.paper',
                                    bgcolor: 'currentColor',
                                    mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    position: 'absolute',
                                    left: 0,
                                    top: 130,
                                    width: 80,
                                    height: 36
                                }}/>
                                <Avatar src={entity.user.photo && Utils.getApiUrl() + "/user/" + entity.user.photo
                                    + "?" + Math.random()} alt={entity.user.name} sx={{
                                    position: 'absolute',
                                    left: theme.spacing(3),
                                    top: 135,
                                    width: 32,
                                    height: 32,
                                    bgcolor: theme.palette.primary.main
                                }}/>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom>{entity.title}</Typography>
                                    {entity.lastModifiedDate &&
                                        <Typography variant="caption" noWrap gutterBottom
                                                    sx={{color: 'text.disabled', display: 'block'}}>
                                            {format(parseISO(entity.lastModifiedDate), 'yyyy-MM-dd hh:mm')}
                                        </Typography>}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default Search;
