import React, {useState} from 'react';
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const Nav = (props) => {

    const {t} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

    const [tabSelected, setTabSelected] = useState(0);
    const handleTabChange = (event, tab) => {
        setTabSelected(tab);
    };

    return (
        <Tabs component="nav" aria-label="Navigation bar" value={tabSelected} onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"} scrollButtons={false} sx={{boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)"}}>
            <Tab component={RouterLink} label={t("nav.home")} to="/" sx={{fontSize: "1rem"}}/>
            <Tab component={RouterLink} label={t("nav.product")} to="/products" sx={{fontSize: "1rem"}}/>
            <Tab component={RouterLink} label={t("nav.users")} to="/users" sx={{fontSize: "1rem"}}/>
            <Tab component={RouterLink} label={t("nav.story")} to="/stories" sx={{fontSize: "1rem"}}/>
        </Tabs>
    );
};

export default Nav;
