import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Snackbar,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import Utils from "../component/Utils";
import {
    Add,
    AutoAwesome,
    Dashboard as DashboardIcon,
    Delete,
    Edit,
    FindInPage,
    MoreVert,
    Search,
    SettingsAccessibility
} from "@mui/icons-material";
import SecurityUtils from "../component/SecurityUtils";
import {format, parseISO} from "date-fns";

const StoriesHome = (props) => {

    const {t} = useTranslation(['story', 'admin']);
    const location = useLocation();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        params: '',
        page: undefined,
        id: '',
        anchorEl: null,
        dialogOpen: false,
        snackbarOpen: false,
        snackbarMessage: ''
    });

    useEffect(() => {
        loadPage(location.pathname);
        setValues((values) => ({
            ...values, params: location.pathname
        }));
    }, [location.pathname]);

    const loadPage = async (params) => {
        await axios.get(Utils.getApiUrl() + params, {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, page: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    const gotoPage = (event, number) => {
        let params = values.params;
        params = Utils.buildParams(params, "/page/", number);
        console.log(params);
        navigate(params);
    };

    const handleMenuOpen = (event, id) => {
        setValues((values) => ({
            ...values, anchorEl: event.currentTarget, id: id
        }))
    };
    const handleMenuClose = () => {
        setValues((values) => ({
            ...values, anchorEl: null
        }))
    };

    const dialogConfirmOpen = (id) => {
        setValues((values) => ({
            ...values, dialogOpen: true, anchorEl: null, id: id
        }))
    };

    const dialogConfirmClose = () => {
        setValues((values) => ({
            ...values, dialogOpen: false
        }));
    };

    const dialogConfirmDelete = async (id) => {
        setValues((values) => ({
            ...values, dialogOpen: false, loading: true
        }));
        await axios.delete(Utils.getApiUrl() + "/home/story/" + id, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    loadPage(values.params);
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            );
    };

    const [filter, setFilter] = useState({
        title: ""
    })


    const handleFilterChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter((values) => ({
            ...values, [name]: value,
        }));
    };

    useEffect(() => {
        if (!values.params) return;
        const timeOutId = setTimeout(() => {
            let params = values.params;
            params = Utils.buildParams(params, "/title/", filter.title);
            loadPage(params);
            setValues((values) => ({
                ...values, params: params
            }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filter.title]);

    const handlePublicCheckbox = async (event, id) => {

        let formData = new FormData();
        formData.append("id", id);
        formData.append("published", event.target.checked);

        await axios.post(Utils.getApiUrl() + "/home/story/publish", formData, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    snackbarOpen(t("story:updateMessage"));
                    setValues((values) => ({
                        ...values, page: {
                            ...values.page,
                            content: values.page.content.map(entity => (entity.id === response.data.id ? response.data : entity))
                        }
                    }));
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, message: error.response.data.message
                    }));
                }
            );
    }

    const snackbarOpen = (message) => {
        setValues((values) => ({
            ...values, snackbarMessage: message, snackbarOpen: true
        }));
    };

    const snackbarClose = () => {
        setValues((values) => ({
            ...values, snackbarOpen: false
        }));
    };

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("story:title")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("story:title")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"
                      sx={{display: 'flex', alignItems: 'center'}}>
                    <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                </Link>
                <Typography fontSize='inherit' sx={{display: 'flex', alignItems: 'center'}}>
                    <SettingsAccessibility sx={{mr: 0.5}} fontSize="inherit"/> {t("story:title")}
                </Typography>
            </Breadcrumbs>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                <OutlinedInput name="title" startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
                               placeholder={t("search")} size='small' sx={{fontWeight: 'fontWeightBold'}}
                               value={filter.title} onChange={handleFilterChange}/>
                <Button variant="contained" component={RouterLink} to="/home/story"
                        startIcon={<Add/>}>{t("admin:new")}</Button>
            </Stack>
            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            {values.page && values.page.content && values.page.content.map(entity =>
                <Card sx={{my: 5, display: 'flex'}} key={entity.id}>
                    <CardActionArea component={RouterLink} to={"/story/" + entity.id} sx={{width: '25%'}}>
                        <CardMedia component="div" sx={{
                            width: '100%', height: '100%', backgroundImage: entity.pictures ?
                                "url(" + Utils.getApiUrl() + "/story/" + entity.id + "/picture/" + (entity.pictures.split(',')[0]) + ")" : "url(/img/banner.jpg)"
                        }}/>
                    </CardActionArea>
                    <Box sx={{display: 'flex', flexDirection: 'column', flex: '3 1 auto'}}>
                        <CardActionArea component={RouterLink} to={"/story/" + entity.id}>
                            <CardContent>
                                <Typography gutterBottom>{entity.title}</Typography>
                                {entity.lastModifiedDate &&
                                    <Typography variant="caption" gutterBottom
                                                sx={{color: 'text.disabled', display: 'block', mt: 1}}>
                                        {format(parseISO(entity.lastModifiedDate), 'yyyy-MM-dd hh:mm')}
                                    </Typography>}
                            </CardContent>
                        </CardActionArea>
                        <CardActions sx={{justifyContent: "right"}}>
                            <FormControlLabel label={t("story:published")} labelPlacement="start"
                                              control={<Switch checked={entity.published}
                                                               onChange={(event) => handlePublicCheckbox(event, entity.id)}/>}/>
                            <IconButton onClick={(event) => handleMenuOpen(event, entity.id)}><MoreVert/></IconButton>
                        </CardActions>
                    </Box>
                </Card>
            )}

            {!values.loading && values.page.totalElements === 0 &&
                <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                            color="text.secondary">
                    <FindInPage fontSize='large'/> {t("translation:view.empty")}
                </Typography>}
            {!values.loading && values.page.totalElements !== 0 && <>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={5}>
                    <Pagination count={values.page.totalPages} variant="outlined" color="primary" onChange={gotoPage}/>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
                    <Typography>{t("translation:view.total", {
                        totalElements: values.page.totalElements,
                        pageNumber: values.page.number,
                        totalPages: values.page.totalPages
                    })}</Typography>
                </Stack>
            </>}
            <Menu open={Boolean(values.anchorEl)} anchorEl={values.anchorEl} onClose={handleMenuClose}
                  onClick={handleMenuClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  PaperProps={{
                      elevation: 0, sx: {
                          width: 150,
                          maxWidth: '100%',
                          overflow: 'visible',
                          mt: 1,
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 15,
                              width: 15,
                              height: 15,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          }
                      },
                  }}>
                <MenuItem component={RouterLink} to={"/home/story/" + values.id} sx={{color: 'text.secondary'}}>
                    <ListItemIcon><Edit/></ListItemIcon>
                    <ListItemText primary={t("admin:edit")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                <MenuItem onClick={() => dialogConfirmOpen(values.id)} sx={{color: 'text.secondary'}}>
                    <ListItemIcon><Delete/></ListItemIcon>
                    <ListItemText primary={t("admin:delete")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>
            <Dialog open={values.dialogOpen} maxWidth="sm" fullWidth
                    onClose={() => dialogConfirmClose()}>
                <DialogTitle>{t("admin:confirm")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("admin:confirmDeleteTitle")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dialogConfirmClose()}>{t("admin:cancel")}</Button>
                    <Button onClick={() => dialogConfirmDelete(values.id)} autoFocus>{t("admin:confirm")}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={values.snackbarOpen} autoHideDuration={3000} onClose={snackbarClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={snackbarClose} severity="success" sx={{width: '100%'}}>{values.snackbarMessage}</Alert>
            </Snackbar>
        </Container>
    );
};

export default StoriesHome;
