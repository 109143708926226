import React, {useState} from "react";
import {Alert, Box, Button, Checkbox, Container, FormControlLabel, Paper, Stack, TextField, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import SecurityUtils from "./component/SecurityUtils";
import {Helmet} from "react-helmet-async";

const Login = (props) => {

    const {t} = useTranslation('login');
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: false,
        message: '',
        login: '',
        loginError: false,
        loginValidate: '',
        password: '',
        passwordError: false,
        passwordValidate: '',
        rememberme: false
    });

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setValues((values) => ({
            ...values, [name]: value,
        }));
    };

    const handleCheckbox = (event) => {
        let name = event.target.name;
        let value = event.target.checked;
        setValues((values) => ({
            ...values, [name]: value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.target.elements["login"].value.trim() === "") {
            setValues((values) => ({
                ...values, loginError: true, loginValidate: t("form.loginValidate")
            }));
            return false;
        }
        if (!(event.target.elements["password"].value.trim().length > 7)) {
            setValues((values) => ({
                ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));

        SecurityUtils.login(values.login, values.password, values.rememberme).then((response) => {
                console.log(response);
                navigate('/home');
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    }

    return (
        <>
            <Container component="main">
                <Helmet>
                    <title>{t("title")} | {t("translation:nav.brand")}</title>
                </Helmet>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{t("title")}</Typography>
                </Paper>

                <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                    {values.message && (
                        <Alert severity="error">{values.message}</Alert>
                    )}

                    <TextField name="login" label={t("form.login")} margin="normal" fullWidth required autoComplete="off" autoFocus
                               value={values.login} onChange={handleChange} error={values.loginError} helperText={values.loginValidate}/>

                    <TextField type="password" name="password" label={t("form.password")} margin="normal" fullWidth required
                               value={values.password} onChange={handleChange} error={values.passwordError} helperText={values.passwordValidate}/>

                    <FormControlLabel control={<Checkbox name="rememberme" checked={values.rememberme} onChange={handleCheckbox} color="primary"/>} label={t("form.rememberme")}/>

                    <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                   sx={{mt: 3, mb: 2}}>{t("form.button")}</LoadingButton>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                        <Button component={RouterLink} to="/forgot">{t("form.forgot")}</Button>
                        <Button component={RouterLink} to="/register">{t("form.createId")}</Button>
                    </Stack>
                </Box>
            </Container>
        </>
    );
};

export default Login;
