import React from 'react';
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";

const Purchases = (props) => {

    const {t} = useTranslation(['purchases', 'admin']);

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("purchases:title")} | {t("translation:nav.brand")}</title>
            </Helmet>
            <Typography variant="h4" gutterBottom>{t("purchases:title")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"> {t("translation:nav.dashboard")} </Link>
                <Typography fontSize='small'> {t("purchases:title")} </Typography>
            </Breadcrumbs>
        </Container>
    );
};

export default Purchases;
