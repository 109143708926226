import React, {useState} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Alert, Box, Breadcrumbs, Container, FormHelperText, Link, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Helmet} from "react-helmet-async";
import SecurityUtils from "../component/SecurityUtils";
import {Dashboard as DashboardIcon, Password as PasswordIcon} from "@mui/icons-material";
import Utils from "../component/Utils";

const Password = (props) => {

    const {t} = useTranslation('password');
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: false,
        message: '',
        password: '',
        passwordError: false,
        passwordValidate: '',
        newPassword: '',
        newPasswordError: false,
        newPasswordValidate: '',
        newPasswordConfirm: '',
        newPasswordConfirmError: false,
        newPasswordConfirmValidate: ''
    });

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setValues((values) => ({
            ...values, [name]: value,
        }));
        if (name === "password") {
            if (value.trim().length > 7) {
                setValues((values) => ({
                    ...values, passwordError: false, passwordValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
                }));
            }
        } else if (name === "newPassword") {
            if (value.trim().length > 7) {
                setValues((values) => ({
                    ...values, newPasswordError: false, newPasswordValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, newPasswordError: true, newPasswordValidate: t("form.newPasswordValidate")
                }));
            }
        } else if (name === "newPasswordConfirm") {
            if (value.trim().length > 7 && value.trim() === values.newPassword) {
                setValues((values) => ({
                    ...values, newPasswordConfirmError: false, newPasswordConfirmValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, newPasswordConfirmError: true, newPasswordConfirmValidate: t("form.newPasswordConfirmValidate")
                }));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!(event.target.elements["password"].value.trim().length > 7)) {
            setValues((values) => ({
                ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
            }));
            return false;
        }
        if (!(event.target.elements["newPassword"].value.trim().length > 7)) {
            setValues((values) => ({
                ...values, passwordError: true, passwordValidate: t("form.newPasswordValidate")
            }));
            return false;
        }
        if (!(event.target.elements["newPasswordConfirm"].value.trim().length > 7 && event.target.elements["newPasswordConfirm"].value.trim() === values.newPassword)) {
            setValues((values) => ({
                ...values, passwordConfirmError: true, passwordConfirmValidate: t("form.newPasswordConfirmValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append("password", values.password);
        formData.append("newPassword", values.newPassword);

        axios.post(Utils.getApiUrl() + "/account/password", formData, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                console.log(response);
                navigate('/info', {
                    state: {
                        alert: "success",
                        summary: t("messages.summary"),
                        details: t("messages.details")
                    }
                });
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    return (
        <>
            <Container component="main">
                <Helmet>
                    <title>{t("translation:nav.password")} | {t("nav.brand")}</title>
                </Helmet>
                <Typography variant="h4" gutterBottom>{t("translation:nav.password")}</Typography>
                <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                    <Link component={RouterLink} underline="hover" color="text.secondary" to="/home" sx={{display: 'flex', alignItems: 'center'}}>
                        <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                    </Link>
                    <Typography fontSize='inherit' sx={{display: 'flex', alignItems: 'center'}}>
                        <PasswordIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.password")}
                    </Typography>
                </Breadcrumbs>

                <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                    {values.message && (
                        <Alert severity="error">{values.message}</Alert>
                    )}

                    <TextField type="password" name="password" label={t("form.password")} margin="normal" fullWidth required
                               value={values.password} onChange={handleChange} error={values.passwordError} helperText={values.passwordValidate}/>

                    <TextField type="password" name="newPassword" label={t("form.newPassword")} margin="normal" fullWidth required
                               value={values.newPassword} onChange={handleChange} error={values.newPasswordError} helperText={values.newPasswordValidate}/>
                    <FormHelperText>{t("form.newPasswordHelp")}</FormHelperText>

                    <TextField type="password" name="newPasswordConfirm" label={t("form.newPasswordConfirm")} margin="normal" fullWidth required
                               value={values.newPasswordConfirm} onChange={handleChange} error={values.newPasswordConfirmError} helperText={values.newPasswordConfirmValidate}/>
                    <FormHelperText>{t("form.newPasswordConfirmHelp")}</FormHelperText>

                    <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                   sx={{mt: 3, mb: 2}}>{t("form.button")}</LoadingButton>
                </Box>
            </Container>
        </>
    );
};

export default Password;
