import React from "react";
import {Container, Paper, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

const Contact = (props) => {

    const {t} = useTranslation();

    return (
        <Container component='main'>
            <Helmet>
                <title>联系我们 | {t("nav.brand")}</title>
            </Helmet>
            <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                <Typography variant="h4">联系我们</Typography>
            </Paper>
            <Typography variant="h4" gutterBottom>
                联系我们
            </Typography>
            <Typography gutterBottom>
                to do
            </Typography>
        </Container>
    );
};

export default Contact;
