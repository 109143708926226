import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Alert, Autocomplete, Box, Breadcrumbs, Container, FormHelperText, LinearProgress, Link, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import SecurityUtils from "../component/SecurityUtils";
import Utils from "../component/Utils";

const UserAdmin = (props) => {

    const {id} = useParams();
    const {t} = useTranslation(['user', 'admin']);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        roles: []
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        username: '',
        email: '',
        phone: '',
        locale: '',
        emailVerified: false,
        phoneVerified: false,
        roles: []
    });

    useEffect(() => {
        if (id) {
            axios.get(Utils.getApiUrl() + "/admin/user/" + id, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                    console.log(response);
                    for (const [name, value] of Object.entries(response.data)) {
                        if (value === null) continue;
                        if (model.hasOwnProperty(name)) {
                            setModel((values) => ({
                                ...values, [name]: value
                            }));
                        }
                    }
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                    return;
                }
            );
        }
        axios.get(Utils.getApiUrl() + "/admin/roles", {headers: SecurityUtils.authHeader()})
            .then((response) => {
                console.log(response);
                response.data.map(role => {
                    setValues((values) => ({
                        ...values, loading: false, roles: [...values.roles, role]
                    }));
                });
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        )
    }, []);

    const handleRoleChange = (event, selectedRoles) => {
        setModel((values) => ({
            ...values, roles: selectedRoles
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setValues((values) => ({
            ...values, loading: true
        }));

        if (model.id && model.id.trim().length > 0) {
            await axios.put(Utils.getApiUrl() + "/admin/user", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                    console.log(response);
                    navigate('/admin/users');
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            );
        } else {
            await axios.post(Utils.getApiUrl() + "/admin/user", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                    console.log(response);
                    navigate('/admin/users');
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            );
        }
    }

    return (
        <Container>
            <Helmet>
                <title>{t("admin:edit")} | {t("user:title")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("user:title")} | {t("admin:edit")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="rgb(33, 43, 54)" to="/home"> {t("translation:nav.dashboard")} </Link>
                <Link component={RouterLink} underline="hover" color="rgb(33, 43, 54)" to="/admin/users"> {t("user:title")} </Link>
                <Typography color="rgb(145, 158, 171)" fontSize='small'> {t("admin:edit")} </Typography>
            </Breadcrumbs>
            <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                {values.message && (
                    <Alert severity="error">{values.message}</Alert>
                )}

                <TextField name="name" label={t("user:name")} value={model.name} variant="standard" margin="normal" fullWidth disabled/>
                <TextField name="username" label={t("user:username")} value={model.username} variant="standard" margin="normal" fullWidth disabled/>

                <TextField name="email" label={t("user:email")} value={model.email} variant="standard" margin="normal" fullWidth disabled/>
                <FormHelperText>{model.emailVerified ? t("user:emailVerified") : t("user:emailNotVerified")}</FormHelperText>

                <TextField name="phone" label={t("user:phone")} value={model.phone} variant="standard" margin="normal" fullWidth disabled/>
                <FormHelperText>{model.phoneVerified ? t("user:phoneVerified") : t("user:phoneNotVerified")}</FormHelperText>

                <Autocomplete name="roles" multiple options={values.roles} getOptionLabel={(option) => option.name}
                              value={model.roles} onChange={handleRoleChange} isOptionEqualToValue={(option, value) => option.id === value.id}
                              filterSelectedOptions sx={{mt: 3}} renderInput={(params) => (
                    <TextField {...params} variant="standard" label={t("user:roles")}/>
                )}/>

                <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth sx={{my: 3}}>{t("admin:save")}</LoadingButton>
            </Box>
        </Container>
    );
};
export default UserAdmin;
