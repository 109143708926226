import React from 'react'
import {Navigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import SecurityUtils from "./SecurityUtils";

const AdminRoute = ({children}) => {

    const {t} = useTranslation();
    const user = SecurityUtils.getCurrentUser();

    console.log(t("error.title"));

    return user ?
        (user.roles.includes("ROLE_ADMIN") ? children :
            (<Navigate to='/info' state={{alert: "error", summary: t("error.title"), details: t("error.403")}}/>))
        : <Navigate to='/login' replace/>;
};
export default AdminRoute;
