import React from "react";
import {useLocation} from "react-router-dom";
import {Alert, AlertTitle, Container, Paper, Typography} from "@mui/material";

const Info = (props) => {

    const location = useLocation();
    const {alert, summary, details} = (location.state) || {};

    return (
        <>
            <Container>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{summary}</Typography>
                </Paper>
            </Container>
            <Container component="main" maxWidth="sm">
                <Alert severity={alert}>
                    <AlertTitle>{summary}</AlertTitle>
                    {details}
                </Alert>
            </Container>
        </>
    );
};

export default Info;
