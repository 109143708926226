import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Alert, Container, LinearProgress, Stack, Typography} from "@mui/material";
import axios from "axios";
import "@victz/ckeditor5-build-classic/build/translations/zh.js"
import {NumericFormat} from "react-number-format";
import SecurityUtils from "./component/SecurityUtils";
import {format, parseISO} from "date-fns";
import {Visibility} from "@mui/icons-material";
import Utils from "./component/Utils";

const Product = (props) => {

    const {id} = useParams();
    const {t} = useTranslation('product');

    const [values, setValues] = useState({
        loading: true,
        message: ''
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        price: '',
        description: '',
        pictures: '',
        attachments: '',
        published: false,
        lastModifiedDate: undefined,
        user: undefined,
        views: undefined
    });

    useEffect(() => {
        if (id) {
            axios.get(Utils.getApiUrl() + "/product/" + id, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        for (const [name, value] of Object.entries(response.data)) {
                            if (model.hasOwnProperty(name)) {
                                setModel((values) => ({
                                    ...values, [name]: value
                                }));
                            }
                        }
                        setValues((values) => ({
                            ...values, loading: false
                        }))
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            setValues((values) => ({
                ...values, loading: false
            }))
        }
    }, []);

    const loadPictureThumbnail = (picture) => {
        let index = picture.lastIndexOf(".");
        let fileNameExt = picture.substring(index);
        let fileNameWithoutExt = picture.substring(0, index);
        return Utils.getApiUrl() + "/product/" + model.id + "/picture/" + fileNameWithoutExt + "_thumbnail" + fileNameExt;
    }

    return (
        <Container component='main'>
            <Helmet>
                <title>{model.name} | {t("translation:nav.brand")}</title>
            </Helmet>

            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            <Typography variant="h4" sx={{my: 5}}>{model.name}</Typography>
            <Typography gutterBottom variant="h4" color="primary">
                {model.price && (<><NumericFormat value={model.price} displayType={'text'} thousandSeparator prefix={'¥'}/></>)}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                {model.lastModifiedDate &&
                <Typography variant="caption" noWrap gutterBottom sx={{color: 'text.disabled', display: 'block'}}>
                    {format(parseISO(model.lastModifiedDate), 'yyyy-MM-dd hh:mm')}
                </Typography>}
                {model.views &&
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Visibility color='disabled' fontSize='small' sx={{mx: 1}}/><Typography variant="caption">{model.views}</Typography>
                </Stack>}
            </Stack>

            <div dangerouslySetInnerHTML={{__html: model.description}}/>

        </Container>
    );
};
export default Product;
