import React from 'react';
import {Box, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Logo = () => {

    const {t} = useTranslation();

    return (
        <Link component={RouterLink} to="/" variant="h4" underline="none" display="inline-flex" alignItems="center">
            <Box component="img" src="/img/victz.svg" sx={{width: 40}}/> {t("nav.brand")}
        </Link>
    );
};

export default Logo;
