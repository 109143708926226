import React, {useState} from "react";
import {Alert, Box, Container, FormHelperText, Paper, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import SecurityUtils from "./component/SecurityUtils";
import {Helmet} from "react-helmet-async";

const Register = (props) => {

    const {t} = useTranslation('register');
    const navigate = useNavigate();

    const patternEmail = /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

    const [values, setValues] = useState({
        loading: false,
        message: '',
        name: '',
        nameError: false,
        nameValidate: '',
        email: '',
        emailError: false,
        emailValidate: '',
        password: '',
        passwordError: false,
        passwordValidate: '',
        passwordConfirm: '',
        passwordConfirmError: false,
        passwordConfirmValidate: ''
    });

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setValues((values) => ({
            ...values, [name]: value,
        }));
        if (name === "name") {
            if (value.trim().length > 1) {
                setValues((values) => ({
                    ...values, nameError: false, nameValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, nameError: true, nameValidate: t("form.nameValidate")
                }));
            }
        } else if (name === "email") {
            if (value.trim() !== "" && patternEmail.test(value.trim())) {
                setValues((values) => ({
                    ...values, emailError: false, emailValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, emailError: true, emailValidate: t("form.emailValidate")
                }));
            }
        } else if (name === "password") {
            if (value.trim().length > 7) {
                setValues((values) => ({
                    ...values, passwordError: false, passwordValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
                }));
            }
        } else if (name === "passwordConfirm") {
            if (value.trim().length > 7 && value.trim() === values.password) {
                setValues((values) => ({
                    ...values, passwordConfirmError: false, passwordConfirmValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, passwordConfirmError: true, passwordConfirmValidate: t("form.passwordConfirmValidate")
                }));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!(event.target.elements["name"].value.trim().length > 1)) {
            setValues((values) => ({
                ...values, nameError: true, nameValidate: t("form.nameValidate")
            }));
            return false;
        }
        if (!(event.target.elements["email"].value.trim() !== "" && patternEmail.test(event.target.elements["email"].value.trim()))) {
            setValues((values) => ({
                ...values, emailError: true, emailValidate: t("form.emailValidate")
            }));
            return false;
        }
        if (!(event.target.elements["password"].value.trim().length > 7)) {
            setValues((values) => ({
                ...values, passwordError: true, passwordValidate: t("form.passwordValidate")
            }));
            return false;
        }
        if (!(event.target.elements["passwordConfirm"].value.trim().length > 7 && event.target.elements["passwordConfirm"].value.trim() === values.password)) {
            setValues((values) => ({
                ...values, passwordConfirmError: true, passwordConfirmValidate: t("form.passwordConfirmValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));
        SecurityUtils.register(values.name, values.email, values.password).then((response) => {
                console.log(response);
                navigate('/info', {
                    state: {
                        alert: "success",
                        summary: t("messages.summary"),
                        details: t("messages.details")
                    }
                });
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    return (
        <>
            <Container component="main">
                <Helmet>
                    <title>{t("title")} | {t("translation:nav.brand")}</title>
                </Helmet>
                <Paper elevation={8} sx={{my: 5, p: 5, background: `url(/img/banner.jpg) center center no-repeat`, backgroundSize: 'cover', color: 'white', textAlign: 'center'}}>
                    <Typography variant="h4">{t("title")}</Typography>
                </Paper>

                <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                    {values.message && (
                        <Alert severity="error">{values.message}</Alert>
                    )}

                    <TextField name="name" label={t("form.name")} margin="normal" fullWidth required autoFocus
                               value={values.name} onChange={handleChange} error={values.nameError} helperText={values.nameValidate}/>
                    <FormHelperText>{t("form.nameHelp")}</FormHelperText>

                    <TextField name="email" label={t("form.email")} margin="normal" fullWidth required
                               value={values.email} onChange={handleChange} error={values.emailError} helperText={values.emailValidate}/>
                    <FormHelperText>{t("form.emailHelp")}</FormHelperText>

                    <TextField type="password" name="password" label={t("form.password")} margin="normal" fullWidth required
                               value={values.password} onChange={handleChange} error={values.passwordError} helperText={values.passwordValidate}/>
                    <FormHelperText>{t("form.passwordHelp")}</FormHelperText>

                    <TextField type="password" name="passwordConfirm" label={t("form.passwordConfirm")} margin="normal" fullWidth required
                               value={values.passwordConfirm} onChange={handleChange} error={values.passwordConfirmError} helperText={values.passwordConfirmValidate}/>
                    <FormHelperText>{t("form.passwordConfirmHelp")}</FormHelperText>

                    <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                   sx={{mt: 3, mb: 2}}>{t("form.button")}</LoadingButton>
                </Box>
            </Container>
        </>
    );
};

export default Register;
